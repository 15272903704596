export class ClientDetails {
    constructor() {
        this.acceptDemat = true;

        this.accountRegisterIdentification1 = "";
        this.accountRegisterIdentification2 = "";
        this.accountDeliveryAddress1 = "";
        this.accountDeliveryPostalCode = "";
        this.accountDeliveryCity = "";
        this.accountDeliveryCountry = "";
        this.accountHeadquarter = "";
        this.accountLegalName = "";

        this.public_entity = "";
        this.title = "";
        this.partner = "";
        this.email = "";

        this.dematId = '';
        this.title0 = "";
        this.first_name = "";
        this.last_name = "";
        this.jobTitle = "";
        this.phone = "";
        this.email_portal = "";
        this.email_address = "";
        this.source_information = ""
        this.conditionCheck = false;
    }
}
