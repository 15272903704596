import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './assets/GlobalStyle.scss';
import VueCompositionAPI from '@vue/composition-api'
import '@progress/kendo-theme-default/dist/all.css'

import { Grid } from '@progress/kendo-vue-grid'
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import VueRouter from 'vue-router'
import Emitter from 'tiny-emitter';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.use(VueRouter)
Vue.use(GridInstaller);

Vue.component('Grid', Grid);


Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
Vue.use(require('vue-moment'));

Vue.prototype.$emitter = new Emitter();

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
