import State from "@/mixins/State";
import DematHeader from "../Demat-Header/Demat-Header.vue";
import DematFooter from "../../Demat-Footer/Demat-Footer.vue";

export default {
    name: 'wizard',
    components: {
        DematHeader,
        DematFooter,
    },
    mixins: [State],
    props: [],
    data: () => ({
        ClientInfo: '',
        ClientDetails: '',
        StepOrder: '',
    }),
    methods: {
        setStepOrder(stepOrder) {
            this.StepOrder = stepOrder;
        },
    },
    computed: {

    },
    created() {
        const path = this.$route.fullPath
        if (path.includes("#")) {
            this.$router.push("/admin")
        }
    },
    mounted() {
        setTimeout(() => {
            this.StepOrder = this.StepOrder
        }, 500);
    },
}


