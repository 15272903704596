import { RouterLink } from 'vue-router'
import { msal } from '../components/Admin/authConfig'
import { PublicClientApplication } from '@azure/msal-browser'
import store, { handleResponse } from '../store/index'
export function registerGuard(router = new RouterLink()) {
    router.beforeEach(
        async (to, from, next) => {
            const { msalInstance } = await msal()
            if (to.matched.some((record) => record.meta.requiresAuth)) {
                if (await isAuthenticated(msalInstance)) {
                    next();
                }
            }
            next();
        }
    )
}

export async function isAuthenticated(
    instance = new PublicClientApplication()
) {
    return instance
        .handleRedirectPromise()
        .then(async () => {
            const accounts = instance.getAllAccounts()
            if (accounts.length > 0) {
                const data = await handleResponse();
                store.commit('SET_USER_DATA', data)
                return true
            }

            return instance
                .loginPopup()
                .then((res) => {
                    const data = {
                        account: res.account,
                        token: res.accessToken,
                        expiresOn: res.expiresOn?.getTime()
                    }
                    store.commit('SET_USER_DATA', data)
                    if (data.token || data.expiresOn) {
                        sessionStorage.setItem('adal.idtoken', data.token)
                        sessionStorage.setItem('expiresOn', data.expiresOn)
                        localStorage.setItem('account', JSON.stringify(data.account))
                    }
                    return true;
                })
                .catch(() => {
                    return false
                })
        })
        .catch(() => {
            return false
        })
}
