<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import Wizard from "./components/Anonymous/Wizard/Wizard.vue"
import globalConfig from "../public/globalConfig";
import api from "./mixins/api";
export default {
    name: 'App',
    components: {
        Wizard,
        globalConfig() {
            return globalConfig;
        },
    },
    mixins: [api],
    data: () => ({
    }),
    created() {
    },
    methods: {
    },
}
</script>

<style>

</style>
