import { ClientDetails } from '@/model/ClientDetails'


export const ClientDetailsModule = {
    namespaced: true,
    data: () => ({

    }),
    state() {
        return {
            clientDetails: new ClientDetails,
        };
    },
    getters: {
    },
    mutations: {
        setClientDetails: (state, value) => {
            state.clientDetails = value;
        },
        setAcceptDemat(state, value) {
            state.clientDetails.acceptDemat = value
        },
        setId(state, value) {
            state.clientDetails.title = value
        },
        setDematId(state, value) {
            state.clientDetails.dematId = value
        },
        setIBS(state, value) {
            state.clientDetails.partner = value
        },
        setSiren(state, value) {
            state.clientDetails.accountRegisterIdentification2 = value
        },
        setSiret(state, value) {
            state.clientDetails.accountRegisterIdentification1 = value
        },
        setRaisonSociale(state, value) {
            state.clientDetails.accountLegalName = value
        },
        setEmail(state, value) {
            state.clientDetails.email = value
        },
        setPublicEntity(state, value) {
            state.clientDetails.public_entity = value
        },
        setAddress1(state, value) {
            state.clientDetails.accountDeliveryAddress1 = value
        },
        setPostalCode(state, value) {
            state.clientDetails.accountDeliveryPostalCode = value
        },
        setCity(state, value) {
            state.clientDetails.accountDeliveryCity = value
        },
        setCountry(state, value) {
            state.clientDetails.accountDeliveryCountry = value
        },
        setCivility(state, value) {
            state.clientDetails.title0 = value
        },
        setFirstName(state, value) {
            state.clientDetails.first_name = value
        },
        setLastName(state, value) {
            state.clientDetails.last_name = value
        },
        setJobTitle(state, value) {
            state.clientDetails.jobTitle = value
        },
        setPhoneNumber(state, value) {
            state.clientDetails.phone = value
        },
        setContactEmail(state, value) {
            state.clientDetails.email_portal = value
        },
        setReceiveEmail(state, value) {
            state.clientDetails.email_address = value
        },
        setConditionCheck(state, value) {
            state.clientDetails.conditionCheck = value
        },
    },
}
