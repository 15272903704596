import Vue from 'vue'
import VueRouter from 'vue-router'
import { registerGuard } from './Guard'
Vue.use(VueRouter)
const routes = [
    {

        path: '/',
        meta: {
            requiresAuth: false
        },
        component: () => import('../components/Anonymous/Wizard/Wizard.vue'),
        children: [
            {
                path: '',
                name: 'Check Client',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/IdentifyClient/IdentifyClient.vue')
            },
            {
                path: 'info',
                name: 'Client Informations',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/ClientInfo/ClientInfo.vue')
            },
            {
                path: 'details',
                name: 'Client Details',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/ClientDetails/ClientDetails.vue')
            },
            {
                path: 'emailSent',
                name: 'Email Landing Page',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/EmailSentLanding/EmailSentLanding.vue')
            },
            {
                path: 'confirmedClientDetails/:id',
                name: 'Confirmed Client Details',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/ClientConfirmedEmail/ClientConfirmedEmail.vue')
            },
            {
                path: 'additionalAccounts',
                name: 'Additional Accounts',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/AdditionalAccounts/AdditionalAccounts.vue')
            },
            {
                path: 'refuseLanding',
                name: 'Refuse Landing',
                meta: {
                    requiresAuth: false
                },
                component: () => import('../components/Anonymous/RefuseLanding/RefuseLanding.vue')
            },
        ],
    },

    {
        path: '/admin',
        meta: {
            requiresAuth: true
        },
        component: () => import('../components/Admin/AdminLandingPage/AdminLandingPage.vue'),
        children: [
            {
                path: '',
                name: 'DematerialisationList',
                meta: {
                    requiresAuth: true
                },
                component: () => import('../components/Admin/DematTempList/DematTempList.vue')
            },
            {
                path: 'archiveList',
                name: 'ArchiveDematerialisationList',
                meta: {
                    requiresAuth: true
                },
                component: () => import('../components/Admin/DematArchivList/DematArchivList.vue')
            },
            {
                path: 'exportPage',
                name: 'ExportPage',
                meta: {
                    requiresAuth: true
                },
                component: () => import('../components/Admin/ExportPage/ExportPage.vue')
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

registerGuard(router)

export default router
