import { render, staticRenderFns } from "./Demat-Header.html?vue&type=template&id=17b2cf0e&scoped=true&"
import script from "./Demat-Header.js?vue&type=script&lang=js&"
export * from "./Demat-Header.js?vue&type=script&lang=js&"
import style0 from "./Demat-Header.scss?vue&type=style&index=0&id=17b2cf0e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b2cf0e",
  null
  
)

export default component.exports