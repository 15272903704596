export default {
    data: () => ({

    }),
    methods: {

    },
    computed: {
        isExistingRequest: {
            get() {
                return this.$store.state.isExistingRequest
            },
            set(value) {
                this.$store.commit('setIsExistingRequest', value)
            }
        },
        isExistingRequestTemp: {
            get() {
                return this.$store.state.isExistingRequestTemp
            },
            set(value) {
                this.$store.commit('setIsExistingRequestTemp', value)
            }
        },
        isExistingRequestArch: {
            get() {
                return this.$store.state.isExistingRequestArch
            },
            set(value) {
                this.$store.commit('setIsExistingRequestArch', value)
            }
        },
        isExistingRequestDelayed: {
            get() {
                return this.$store.state.isExistingRequestDelayed
            },
            set(value) {
                this.$store.commit('setIsExistingRequestDelayed', value)
            }
        },
        isExistingRequestActive: {
            get() {
                return this.$store.state.isExistingRequestActive
            },
            set(value) {
                this.$store.commit('setIsExistingRequestActive', value)
            }
        },
        clientDetails: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setClientDetails', value)
            }
        },
        acceptDemat: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.acceptDemat
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setAcceptDemat', value)
            }
        },
        title: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.title
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setId', value)
            }
        },
        DematId: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.dematId
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setDematId', value)
            }
        },
        IBS: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.partner
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setIBS', value)
            }
        },
        siren: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountRegisterIdentification2
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setSiren', value)
            }
        },
        siret: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountRegisterIdentification1
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setSiret', value)
            }
        },
        RaisonSociale: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountLegalName
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setRaisonSociale', value)
            }
        },
        email: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.email
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setEmail', value)
            }
        },
        accountFormeJuridique: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.public_entity
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setPublicEntity', value)
            }
        },
        Address1: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountDeliveryAddress1
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setAddress1', value)
            }
        },
        PostalCode: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountDeliveryPostalCode
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setPostalCode', value)
            }
        },
        City: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountDeliveryCity
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setCity', value)
            }
        },
        Country: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.accountDeliveryCountry
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setCountry', value)
            }
        },
        Civility: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.title0
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setCivility', value)
            }
        },
        FirstName: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.first_name
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setFirstName', value)
            }
        },
        LastName: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.last_name
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setLastName', value)
            }
        },
        JobTitle: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.jobTitle
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setJobTitle', value)
            }
        },
        PhoneNumber: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.phone
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setPhoneNumber', value)
            }
        },
        ContactEmail: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.email_portal
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setContactEmail', value)
            }
        },
        ReceiveEmail: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.email_address
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setReceiveEmail', value)
            }
        },
        ConditionCheck: {
            get() {
                return this.$store.state.clientDetailsModule.clientDetails.conditionCheck
            },
            set(value) {
                this.$store.commit('clientDetailsModule/setConditionCheck', value)
            }
        },

    },
}