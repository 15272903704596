import dematClient from '../http/demat-http-common'

/**
 * @mixin
 */
export default {
    data: () => ({
    }),
    props: {

    },
    computed: {
    },
    methods: {

        get(url) {
            return new Promise((resolve, reject) => {
                dematClient
                    .get(url)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },
        post(url, payload) {
            return new Promise((resolve, reject) => {
                dematClient
                    .post(url, payload)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },
        delete(url) {
            return new Promise((resolve, reject) => {
                dematClient
                    .delete(url)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },
        patch(url, payload) {
            return new Promise((resolve, reject) => {
                dematClient
                    .patch(url, payload)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },
    },
};
