import axios from 'axios'
import store from '../store/index'
import { emitter } from '../components/Admin/events/eventbus'
import { msal } from '../components/Admin/authConfig'

const dematClient = axios.create({
    headers: {
        'Content-type': 'application/json'
    }
})

export const fetchTokenAsync = async () => {
    const { msalInstance, msalConfig } = await msal()
    const currentAccounts = msalInstance.getAllAccounts()
    if (currentAccounts.length === 0) {
        return null
    } else if (currentAccounts.length === 1) {
        const account = currentAccounts[0]
        store.commit('SET_USER_DATA', { account })
        try {
            const response = await msalInstance.acquireTokenSilent({
                scopes: [msalConfig.auth.clientId + '/.default'],
                account: currentAccounts[0]
            })

            return response.accessToken
        } catch (e) {
            return null
        }
    }

    return null
}

dematClient.interceptors.request.use(
    async (config) => {
        const token = await fetchTokenAsync()
        config.baseURL = window.baseUrl
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        }

        return config
    },
    async (error) => {
        Promise.reject(error)
    }
)

dematClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error == null ||
            error.response == null ||
            [500, 503].includes(error.response.status)
        ) {
            emitter.emit('errorEvent', 'serverError')
            throw error
        }

        if (error.response.data == 'Unauthorized') {
            emitter.emit('errorEvent', 'unauthorisedError')
        }

        const request = error.config

        if (error.response.status === 401 && !request._retry) {
            request._retry = true
            await store.dispatch('SignIn')

            return dematClient(request)
        }
        throw error
    }
)
export default dematClient
