import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { ClientDetailsModule } from './ClientDetailsModule';
import { msal } from '../components/Admin/authConfig'

Vue.use(Vuex)
export const handleResponse = async (response) => {
    let account
    let token
    let expiresOn
    const { msalInstance, msalConfig } = await msal()
    const currentAccounts = msalInstance.getAllAccounts()
    if (currentAccounts.length === 0) {
        // Add choose account code here
        await msalInstance.loginRedirect()
    } else if (currentAccounts.length === 1) {
        account = currentAccounts[0]
        try {
            const response = await msalInstance.acquireTokenSilent({
                scopes: [msalConfig.auth.clientId + '/.default'],
                account: currentAccounts[0]
            })
            token = response.accessToken
            expiresOn = response.expiresOn?.getTime()
        } catch (error) {
            sessionStorage.clear()
            localStorage.clear()
        }
    }

    return { account, token, expiresOn }
}
export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        key: 'ClientWizard',
        paths: [
            'clientDetailsModule', 'isExistingRequest', 'isExistingRequestTemp', 'isExistingRequestArch', 'isExistingRequestDelayed', 'isExistingRequestActive'
        ],
    }),],
    data: () => ({

    }),
    state() {
        return {
            account: undefined,
            token: sessionStorage.getItem('adal.idtoken'),
            isAdmin: false,
            accessToken: '',
            isExistingRequest: false,
            isExistingRequestTemp: false,
            isExistingRequestArch: false,
            isExistingRequestDelayed: false,
            isExistingRequestActive: false,
        };
    },
    mutations: {
        SET_USER_DATA(state, data) {
            state.account = data.account
        },
        setIsExistingRequest(state, data) {
            state.isExistingRequest = data
        },
        setIsExistingRequestTemp(state, data) {
            state.isExistingRequestTemp = data
        },
        setIsExistingRequestArch(state, data) {
            state.isExistingRequestArch = data
        },
        setIsExistingRequestDelayed(state, data) {
            state.isExistingRequestDelayed = data
        },
        setIsExistingRequestActive(state, data) {
            state.isExistingRequestActive = data
        }
    },
    actions: {
        async SignIn({ commit }) {
            const { msalInstance } = await msal()

            return msalInstance
                .handleRedirectPromise()
                .then(async (res) => {
                    const data = await handleResponse(res)

                    commit('SET_USER_DATA', data)
                    if (data.token || data.expiresOn) {
                        sessionStorage.setItem('adal.idtoken', data.token)
                        sessionStorage.setItem('expiresOn', data.expiresOn)
                        localStorage.setItem('account', JSON.stringify(data.account))
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    },
    getters: {
        getUserName: (state) => {
            let name;
            let shortName;
            let email;
            let homeAccountId;

            if (state.account) {
                name = state.account.name
                email = state.account.username
                homeAccountId = state.account.homeAccountId
                shortName = state.account.name
                    ?.split(' ')
                    .map((i) => i[0])
                    .reverse()
                    .join('')
            }

            return { homeAccountId, email, name, shortName }
        }
    }, modules: {
        clientDetailsModule: ClientDetailsModule
    },
})
